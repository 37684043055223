.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.center-screen {
  user-select: none;
  height: 112px;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -56px;
  text-align: center;
}
.name, i {
  text-shadow: 1px 1px 2px #b4af98;
}
.sub-text a {
  margin: 0 3px;
}
.sub-text {
  font-size: 2.2em;
}
.sub-text a {
  text-decoration: none;
  transition: all ease-in-out 0.25s;
}
.sub-text a:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  color: #eee;
}